.shipment-success-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
}

.shipment-success-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;
    text-align: center;
}

.shipment-success-popup-content h2 {
    color: #4CAF50; /* Green text for success */
    font-size: 1.8em;
    font-family: "Bungee";
}

.shipment-success-popup-content p {
    line-height: 1.5;
}

.shipment-success-popup-content button {
    background: #25AF19; /* The button's background color */
    color: white; /* The text color */
    font-size: 1.2em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 60%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
    margin-bottom: 20px;
}

.shipment-success-popup-content button:hover {
    background-color: #45a049; /* Slightly darker green */
}
