.gradient-background {
    display: flex; /* Establishes this container as a flex container */
    flex-direction: column; /* Aligns children elements in a column */
    align-items: center; /* Centers children elements horizontally */
    background: linear-gradient(180deg, #F21103, #FFC2AF, #DAF0FD, #88C6FF);
    min-height: 100vh;
    box-sizing: border-box;
    text-align: center; /* Centers text for all child elements */
}

.kuji-banner {
    margin-top: 20px;
}

.kuji-title-box {
    background: linear-gradient(225deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 50%, transparent 50%), #0A89FF;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    position: relative;
    top: 15px;
    border-radius: 10px;
    box-shadow: 0 5px 0 0 #5013FF;
    width: 30%;
    font-weight: bold;
    font-size: 1.3em;
}

.banner {
    max-width: 75%; /* Adjust based on your logo's size */
    height: auto; /* Keep the aspect ratio of the image */
    border-radius: 25px;
    border: 3px solid black;
    box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2); /* Increased blur radius and adjusted opacity */
}

.text-box {
    padding: 50px 20px;
    box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2); /* Increased blur radius and adjusted opacity */
    background-color: white;
    border-radius: 25px;
    font-size: 0.8em;
    max-width: calc(75% - 35px); /* Calculate the max-width */
    max-height: 60px;
    margin: auto;
    margin-top: -50px;
    font-family: 'Red Hat Mono';
}

.kuji-play-button {
    background: linear-gradient(225deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 50%, transparent 50%), #94E7FC;
    color: white;
    border: none;
    padding: 5px 30px;
    margin: 20px 0;
    border-radius: 20px;
    font-size: 1.8em;
    cursor: pointer;
    box-shadow: 0 5px 0 0 #08B9FF;
    transition: all 0.3s ease;
    width: 45%;
    font-weight: bold;
}

.kuji-play-button:hover {
    background-color: #009ACD;
}

.bb-banner {
    margin-top: 30px;
}

.bb-title-box {
    background: linear-gradient(225deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 50%, transparent 50%), #FF0AAC;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    position: relative;
    top: 15px;
    border-radius: 10px;
    box-shadow: 0 5px 0 0 #FF1392;
    width: 30%;
    font-weight: bold;
    font-size: 1.3em;
}

.bb-play-button {
    background: linear-gradient(225deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 50%, transparent 50%), #FE4CA1;
    color: white;
    border: none;
    padding: 5px 30px;
    margin: 20px 0;
    border-radius: 20px;
    font-size: 1.5em;
    cursor: pointer;
    box-shadow: 0 5px 0 0 #FF08BA;
    transition: all 0.3s ease;
    width: 60%;
    font-weight: bold;
}

.bb-play-button:hover {
    background-color: #A70854;
}

.ticket-icon {
    height: 30px; /* Adjust based on the size of your icon */
    margin-right: 10px; /* Adjust spacing between icon and text */
}

.bb-icon {
    height: 30px; /* Adjust based on the size of your icon */
    margin-right: 10px; /* Adjust spacing between icon and text */
}

.login-success-message {
    background: #25AF19;
    color: white;
    width: 100%;
    padding: 20px 0px;
    font-size: 1.2em;
    animation: fadeOut 5s forwards;
    /* Additional styling for the message */
}
  
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        visibility: hidden;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999; /* Ensure it's below the popup but above other content */
}
  
.mobile-warning-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    z-index: 1000;
    width: 80%;
    max-width: 300px;
    text-align: center;
}
  
.mobile-warning-popup p {
    margin: 0 0 20px 0;
}
  
.mobile-warning-popup button {
    padding: 10px 20px;
    background: #DE3163;
    box-shadow: 0 4px #800020;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Bungee';
}

.home-logo-container {
    text-align: center;
    margin-top: 30px;
}
  
.home-logo {
    height: 140px; /* Adjust the size of your logo */
}

.home-promo-banner-container {
    text-align: center;
    margin-top: 30px;
}
  
.home-promo-banner {
    width: 90%;
    height: auto;
    border: 3px solid black; /* Adds a black border */
    border-radius: 10px;
}