.profile-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the flex container */
    align-items: center;
    margin-top: 10px;
    margin-bottom: 50px;
}

.edit-profile-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the flex container */
    margin-top: 10px;
    padding: 0px;
    margin-bottom: 50px;
}

.user-information {
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 20px;
    text-decoration: underline;
}

.shipping-address {
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 20px;
    text-decoration: underline;
}

.address {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Allow items to wrap if not enough space */
}

.profile-title {
    font-family: 'Bungee';
    width: 70%;
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis for overflow */
    text-align: center; /* Center the text */
    line-height: 1.6;
}

.edit-profile {
    font-family: 'Bungee';
    width: 100%;
    text-align: center; /* Center the text */
}

.input-group {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between;
}

.input-group label, .image-group label {
    margin-left: 15px;
    font-size: 0.9em;
}

.button {
    color: white; /* The text color */
    font-size: 1em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 10px; /* Rounded corners */
    padding: 8px 10px;
    margin: 0px 15px; /* Margin above and below the button */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    text-align: center;
    width: 50%;
}

.profile-button {
    color: white; /* The text color */
    font-size: 1em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 10px; /* Rounded corners */
    padding: 8px 10px;
    margin: 10px 15px; /* Margin above and below the button */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    text-align: center;
    width: 50%;
}

.edit-button {
    background: #6082B6;
    box-shadow: 0 4px #191970; /* Shadow effect to make the button look clickable */
}

.edit-button:hover {
    background-color: #0056b3;
}

.save-profile-button {
    background: #25AF19; /* The button's background color */
    box-shadow: 0 4px #184012; /* Shadow effect to make the button look clickable */
    margin-top: 15px;
    margin-bottom: 15px;
}

.cancel-profile-button {
    background: #DE3163;
    box-shadow: 0 4px #800020;
}

.logout-button {
    background: #DE3163;
    box-shadow: 0 4px #800020;
}

.logout-button:hover {
    background: #800020;
}

.order-button {
    background: #25AF19;
    box-shadow: 0 4px #184012;
}

.order-button:hover {
    background: #184012;
}

.profile-image-preview {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid black; /* Adjust the size as needed */
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5); /* This creates the drop shadow */
}

.edit-image-preview {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover; /* Ensure the image covers the area without distorting aspect ratio */
}

.edit-profile-container,
.input-group {
  width: 100%; /* Ensure the container takes the full width */
}

.input-group input[type="text"],
.image-group input[type="file"] {
  width: 55%; /* Make inputs take the full width */
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px; /* Space between each input group */
  padding: 8px 5px;
  margin-right: 10px;
}

.input-group select {
    width: 58%; /* Make inputs take the full width */
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px; /* Space between each input group */
    padding: 8px 5px;
    margin-right: 10px;
}


.image-group {
    margin-bottom: 20px;
}

.file-select {
    margin-left: 15px;
}

.profile-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profile-update-success-message {
    background: #25AF19;
    color: white;
    text-align: center;
    width: 100%;
    padding: 20px 0;
    font-size: 1.2em;
    animation: fadeOut 5s forwards;
}

.profile-update-prompt {
    width: 70%;
    text-align: center;
    align-items: center;
    justify-content: center;
}