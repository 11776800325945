.selfcollect-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.selfcollect-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Add shadow */
}

.selfcollect-confirm-button {
    background: #25AF19;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    flex: 1; /* Take up less space than the input */
    box-shadow: 0 3px #184012; /* Shadow effect */
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee';
    padding: 5px;
}

.selfcollect-cancel-button {
    background: #DE3163;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    flex: 1; /* Take up less space than the input */
    box-shadow: 0 3px #800020; /* Shadow effect */
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee';
    padding: 5px;
    margin-left: 50px;
}

.selfcollect-popup-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
}