/* AdminLogin.css */
.admin-login-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-message {
    color: red;
}

.admin-login-form div {
    margin-bottom: 15px;
}

.admin-login-form button {
    padding: 10px 30px;
    margin-top: 15px;
}  