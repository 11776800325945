.new-popular-header {
    font-family: 'Zen Dots', cursive; /* Apply the Zen Dots font */
    font-size: 2.4em; /* Adjust the size as needed */
    color: #333; /* Adjust the color as needed */
    text-align: center; /* Center the text */
    margin-top: 50px; /* Add space above the header */
    width: 100%;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Drop shadow 4px down with 25% blur */
    margin-bottom: 0px;
}

.product-banner {
    position: relative;
    width: 310px;
    margin: 0;
    max-height: 260px;
}

.product-title-box-preorder {
    background: #0A89FF;
    color: white;
    display: inline-flex;
    align-items: center; /* Vertically center the text */
    justify-content: center; /* Align text to the left */
    padding: 8px 12px;
    position: relative;
    border-radius: 10px;
    font-weight: bold;
    font-size: 0.8em;
    z-index: 2; /* Ensure it's above the banner */
    top: 15px; /* Adjust this value to position the title box above the banner */
    width: 50%;
    white-space: nowrap; /* Keep text in a single line */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add an ellipsis if the text is too long */
    left: 10px;
}

.product-title-box {
    background: #0A89FF;
    color: white;
    display: inline-flex;
    align-items: center; /* Vertically center the text */
    justify-content: center; /* Align text to the left */
    padding: 8px 12px;
    position: relative;
    border-radius: 10px;
    font-weight: bold;
    font-size: 0.8em;
    z-index: 2; /* Ensure it's above the banner */
    top: 15px; /* Adjust this value to position the title box above the banner */
    width: 60%;
    white-space: nowrap; /* Keep text in a single line */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add an ellipsis if the text is too long */
}

.product-title-text {
    white-space: nowrap; /* Keep text in a single line */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add an ellipsis if the text is too long */
    display: block; /* Makes the overflow styles apply correctly */
    max-width: 100%; /* Ensure it doesn't exceed the parent box */
}

.price-tag {
    position: absolute;
    bottom: 15px; /* Place it at the bottom */
    left: 15px; /* Position it from the left side */
    padding: 7px 15px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    font-size: 1em;
    background: linear-gradient(225deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 50%, transparent 50%), #FB782E;
    box-shadow: 0 5px 0 0 #F25903;
    z-index: 2; /* Ensure it's above the banner */
}

.price-tag, .items-left-tag {
    position: absolute;
    z-index: 2; /* Ensure it's above the banner */
    bottom: 65px; /* Position from the bottom of the product banner */
}

.items-left-tag {
    padding: 5px 12px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    display: flex; /* Use flexbox to align the icon and text */
    align-items: center; /* Center the items vertically */
    justify-content: center; /* Center the items horizontally */
    position: absolute;
    background-color: #FF79EA;
    box-shadow: 0 5px 0 0 #E530B6;
    right: 15px; /* Position from the right */
}

.items-left-tag.lottery-enabled {
    background-color: #00C853; /* Shade of green */
    box-shadow: 0 5px 0 0 #009624; /* Darker green for shadow */
}

.items-left-tag.promotion-enabled {
    background-color: #FF3131; /* Shade of green */
    box-shadow: 0 5px 0 0 #8B0000; /* Darker green for shadow */
}

.product-card-chance-label {
    font-size: 0.45em;
    color: yellow;
}

.product-card-promotion-label {
    font-size: 0.45em;
    color: yellow;
}

.product-card-items-left-tag-chance {
    display: flex;
    flex-direction: column;
}

.ticket-icon {
    height: 30px; /* Adjust based on the size of your icon */
    margin-right: 10px; /* Adjust spacing between icon and text */
}

.bb-icon {
    height: 30px; /* Adjust based on the size of your icon */
    margin-right: 10px; /* Adjust spacing between icon and text */
}

.product-play-button {
    background: linear-gradient(225deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 50%, transparent 50%), #94E7FC;
    color: white;
    border: none;
    padding: 5px 30px;
    margin-top: 10px;
    border-radius: 20px;
    font-size: 1.8em;
    cursor: pointer;
    box-shadow: 0 5px 0 0 #08B9FF;
    transition: all 0.3s ease;
    width: 50%;
    font-weight: bold;
}

.product-play-button:hover {
    background-color: #009ACD;
}

.product-poster {
    width: 95%; /* Ensure the width always fills the container */
    max-height: 170px; /* Set a fixed maximum height */
    object-fit: cover; /* Ensures the image fills the box while maintaining aspect ratio and cropping */
    height: 170px; /* Force the height to be fixed at 170px */
    border-radius: 15px;
    border: 3px solid black;
    box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2); /* Add the shadow effect */
    position: relative;
}

.product-image-wrapper {
    position: relative;
    display: inline-block;
    width: 95%; /* Ensure the wrapper also takes the full width */
    max-height: 260px; /* Set the height to 170px to match the image */
    border-radius: 15px;
    overflow: hidden; /* Ensures the gradient and image are clipped to the container's border */
}

.product-image-wrapper::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40%; /* Cover the bottom 30% of the image */
    background: linear-gradient(
        to top, 
        rgba(0, 0, 0, 1),  /* Darkest at the bottom */
        rgba(0, 0, 0, 0.0)   /* Fully transparent at the top */
    );
    pointer-events: none; /* Let clicks pass through the overlay */
}

.product-poster.hidden {
    display: none; /* Hide the image while it's loading */
}

.pre-order-icon {
    position: relative;
    z-index: 2; /* Ensure it's above the banner */
    top: 50px; /* Adjust this value to position the title box above the banner */
    width: 80px;
    left: 30px;
}

.promotion-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.promotion-tag {
    text-align: center;
    background-color: #4FFFB0;
    color: #1B4D3E;
    padding: 5px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 0.9em;
    margin-top: 20px;
    width: 80%;
}

.product-text-box-promotion {
    padding: 50px 20px;
    box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2); /* Increased blur radius and adjusted opacity */
    background-color: white;
    border-radius: 25px;
    max-width: calc(75% - 35px); /* Calculate the max-width */
    height: 70px;
    max-height: 70px;
    margin: auto;
    margin-top: -60px;
    margin-bottom: 30px;
    font-family: 'Red Hat Mono';
}

.product-text-box-lottery {
    padding: 50px 20px;
    box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2); /* Increased blur radius and adjusted opacity */
    background-color: white;
    border-radius: 25px;
    max-width: calc(75% - 35px); /* Calculate the max-width */
    height: 70px;
    max-height: 70px;
    margin: auto;
    margin-top: -60px;
    margin-bottom: 30px;
    font-family: 'Red Hat Mono';
}

.product-text-box {
    padding: 50px 20px;
    box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2); /* Increased blur radius and adjusted opacity */
    background-color: white;
    border-radius: 25px;
    max-width: calc(75% - 35px); /* Calculate the max-width */
    height: 40px;
    max-height: 60px;
    margin: auto;
    margin-top: -60px;
    margin-bottom: 30px;
    font-family: 'Red Hat Mono';
}

.product-card-text-top {
    margin-top: 20px;
    margin-bottom: 0px;
}

.product-card-text {
    margin-top: 5px;
    margin-bottom: 0px;
}

.promotion {
    color: #FF3131;
    font-weight: bold;
    font-size: 0.9em;
}

.lottery {
    color: #FF3131;
    font-weight: bold;
    font-size: 0.7em;
}

.challenge {
    color: #FF3131;
    font-weight: bold;
    font-size: 0.7em;
}

.status {
    font-size: 0.9em;
}

.doublechance {
    color: #3b3b3b;
    font-size: 0.75em;
}

.product-token-icon {
    height: 22px;
    margin-left: 3px;
}

.token-price {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}