.contact-container {
    padding: 20px;
    text-align: left;
    background-color: #f5f5f5;
    min-height: 50vh; /* Adjust based on your header/footer size */
}

.contact-title {
    text-align: center;
    color: #333;
}

.contact-email {
    margin-top: 20px;
    font-size: 16px;
    color: #333; /* Adjust the color to fit your site's theme */
}

.contact-email a {
    color: #1a0dab; /* Typically hyperlink blue */
    text-decoration: none; /* Optional: removes underline */
}

.contact-email a:hover {
    text-decoration: underline; /* Re-add underline on hover for clarity */
}
