.results-popup {
    top: 0;
    left: 0;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align content to the top */
    z-index: 10;
    padding: 50px 0;
    box-sizing: border-box; /* Make sure padding doesn't affect the overall size */
    margin-bottom: 50px;
    overflow-y: auto; /* Enable scrolling */
}

.text {
    font-weight: bold;
    color: white;
    text-shadow:
    3px 3px 0 #000,
    -3px 3px 0 #000,
    -3px -3px 0 #000,
    3px -3px 0 #000;
    font-size: 2.5em;
    margin: 0px;
}

.tickets-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    padding: 50px 50px;
}

.ticket {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}

.ticket img {
    display: block; /* remove default image inline behavior */
    width: 200px; /* Adjust as needed */
}

.prize-text {
    position: absolute;
    top: 53%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust position precisely */
    color: white;
    font-weight: bold;
    font-family: 'Bungee';
    font-size: 1.5em;
    /* Add more styles if needed */
}

.try-button {
    background: #25AF19; /* The button's background color */
    color: white; /* The text color */
    font-size: 1.5em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    margin: 15px 0; /* Margin above and below the button */
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 60%;
    font-family: 'Bungee';
    text-shadow: 0 4px rgba(0, 0, 0, 0.25);
}

.browse-button {
    background: #7A85F4; /* The button's background color */
    color: white; /* The text color */
    font-size: 1.3em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    margin: 15px 0; /* Margin above and below the button */
    box-shadow: 0 6px #4B4B9E; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 60%;
    font-family: 'Bungee';
    text-shadow: 0 4px rgba(0, 0, 0, 0.25);
}