.create-trade {
    margin: 0 auto;
    width: 100%;
}
  
.create-trade h1 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 30px;
    font-family: "Bungee";
}
  
.section {
    margin-bottom: 20px;
}
  
.order-card, .product-card {
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    background: #f8f9fa;
}
  
ul {
    list-style: none;
    padding-left: 0;
}
  
li {
    padding: 5px 0;
}

.product-prizes, .order-prizes {
    margin-top: 10px;
    width: 100%;
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-left: 40px;
}

.selected-prizes-offered, .selected-prizes-wanted {
    margin-top: 20px;
}
  
.selected-prizes-offered ul, .selected-prizes-wanted ul {
    list-style: none;
    padding-left: 0;
}

.add-prizes-button {
    color: white;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 6px #002D62; /* Shadow effect to make the button look clickable */
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee';
    display: inline;
    margin: 10px 10px;
    padding: 10px 15px;
    background-color: #007bff;
    width: 40%;
}

.clear-prizes-button {
    color: white;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 6px #800020; /* Shadow effect to make the button look clickable */
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee';
    display: inline;
    margin: 10px 0;
    padding: 10px 15px;
    background-color: #DE3163;
    width: 40%;
}

.order-prizes h3 {
    margin-top: 10px;
}