/* Overlay for the popup */
.topup-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup content box */
.topup-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 80%;
    text-align: center;
}

/* Heading style */
.topup-popup-content h2 {
    margin-top: 0;
    font-size: 1.5em;
    color: #333;
}

/* Paragraph text */
.topup-popup-content p {
    font-size: 1em;
    margin: 0;
}

/* Container for the buttons */
.topup-popup-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.popup-button-topup {
    margin-top: 20px;
    background: #25AF19;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 50%;
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee';
    font-size: 20px;
}

.popup-button-proceed {
    margin-top: 20px;
    background: #3498db;
    color: white;
    padding: 12px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 50%;
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee';
    font-size: 16px;
}

.popup-button-proceed:hover {
    background-color: #2980b9;
}  