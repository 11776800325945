.play-product-banner {
    position: relative;
    margin-top: 0px;
}

.play-product-banner-img {
    width: 75%; /* Adjust based on your logo's size */
    max-height: 170px; /* Set a fixed maximum height */
    object-fit: cover; /* Ensures the image fills the box while maintaining aspect ratio and cropping */
    height: 170px; /* Force the height to be fixed at 170px */
    border-radius: 25px;
    border: 2px solid black;
    box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2); /* Increased blur radius and adjusted opacity */
}

.play-product-banner-container {
    display: flex;
    flex-direction: column; /* Align items in a column */
    justify-content: center; /* Vertically center the items */
    align-items: center; /* Horizontally center the items */
    margin-top: 10px; /* Add some space from the top if needed */
    position: relative;
}

.play-product-title-box {
    background: #0A89FF;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border-radius: 7px;
    font-weight: bold;
    font-size: 0.8em;
    z-index: 2; /* Ensure it's above the banner */
    top: 10px; /* Space between the title and the image */
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.play-product-banner-img {
    max-width: 75%;
    height: auto;
    border-radius: 25px;
    border: 2px solid black;
    box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2);
}

.play-price-tag, .play-price-tag-preorder {
    position: absolute;
    padding: 7px 15px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    background: linear-gradient(225deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 50%, transparent 50%), #FB782E;
    box-shadow: 0 5px 0 0 #F25903;
    left: 15px; /* Position from the left */
}

.play-price-tag-preorder, .play-items-left-tag-preorder {
    position: absolute;
    z-index: 2; /* Ensure it's above the banner */
    bottom: 330px; /* Position from the bottom of the product banner */
}

.play-price-tag, .play-items-left-tag {
    position: absolute;
    z-index: 2; /* Ensure it's above the banner */
    bottom: 300px;
}

.play-items-left-tag, .play-items-left-tag-preorder {
    padding: 5px 12px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    display: flex; /* Use flexbox to align the icon and text */
    align-items: center; /* Center the items vertically */
    justify-content: center; /* Center the items horizontally */
    position: absolute;
    background-color: #FF79EA;
    box-shadow: 0 5px 0 0 #E530B6;
    right: 15px; /* Position from the right */
}

.ticket-icon {
    height: 30px; /* Adjust based on the size of your icon */
    margin-right: 10px; /* Adjust spacing between icon and text */
}

.bb-icon {
    height: 30px; /* Adjust based on the size of your icon */
    margin-right: 10px; /* Adjust spacing between icon and text */
}

.play-product-buy-button {
    background: #7A85F4;
    color: white;
    border: none;
    padding: 5px 30px;
    margin: 20px 0;
    border-radius: 20px;
    font-size: 1.8em;
    cursor: pointer;
    box-shadow: 0 6px #4B4B9E;
    transition: all 0.3s ease;
    width: 50%;
    font-family: 'Bungee';
    text-shadow: 0 4px rgba(0, 0, 0, 0.25);
}

.play-product-buy-out-button {
    background: #25AF19;
    color: white;
    border: none;
    padding: 5px 0px;
    margin-bottom: 30px;
    border-radius: 20px;
    font-size: 1.4em;
    cursor: pointer;
    box-shadow: 0 6px #184012;
    transition: all 0.3s ease;
    width: 50%;
    font-family: 'Bungee';
    text-shadow: 0 4px rgba(0, 0, 0, 0.25);
}

.play-product-buy-button:disabled {
    background-color: grey;
    box-shadow: 0 6px grey;
}

.play-product-buy-button:disabled:hover {
    background-color: grey;
    box-shadow: 0 6px grey;
    opacity: 0.9;
}

.play-product-buy-button:hover {
    background-color: #009ACD;
}

.ticket-selection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.ticket-decrease-button {
    background: #DE3163;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 1em;
    cursor: pointer;
    box-shadow: 0 4px #800020;
    transition: all 0.3s ease;
    width: 15%;
    font-family: 'Bungee';
    text-shadow: 0 4px rgba(0, 0, 0, 0.25);
    padding: 5px 0;
}

.ticket-increase-button {
    background: #25AF19;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 1em;
    cursor: pointer;
    box-shadow: 0 4px #184012;
    transition: all 0.3s ease;
    width: 15%;
    font-family: 'Bungee';
    text-shadow: 0 4px rgba(0, 0, 0, 0.25);
    padding: 5px 0;
}

.ticket-count-input {
    width: 35%;
    text-align: center;
    border: none;
    margin: 0 20px;
    padding: 5px 0;
    font-size: 1.5em;
    border-radius: 20px;
    background-color: #0A89FF;
    color: white;
    font-family: 'Bungee';
    text-shadow: 0 4px rgba(0, 0, 0, 0.25);
}

.terms-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}
  
.terms-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 15px rgba(0,0,0,0.3);
    width: 80%;
    max-width: 500px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
  
.terms-content h2 {
    margin-top: 0;
}

.terms-popup-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Ensures vertical alignment is centered, if needed */
    padding: 0 10px; /* Adds padding to the container, adjust as needed */
    width: 100%;
}

.terms-popup-confirm-button, .terms-popup-cancel-button {
    flex: 1; /* Each button takes up half the container */
    margin: 0 5px; /* Adds margin to the sides of each button */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    padding: 10px 20px; /* Adequate padding for tapping on mobile */
    border-radius: 20px; /* Rounded corners */
}

.terms-popup-confirm-button {
    background: #25AF19; /* Green background */
    box-shadow: 0 4px #184012; /* Darker green shadow for 3D effect */
    border: none; /* No border */
    color: white; /* White text */
    width: 40%;
}

.terms-popup-cancel-button {
    background: #DE3163; /* Red background */
    box-shadow: 0 4px #800020; /* Darker red shadow for 3D effect */
    border: none; /* No border */
    color: white; /* White text */
}

.buy-and-arrival {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.play-product-arrival {
    color: white;
    font-weight: bold;
    margin-bottom: 10px;
}

.play-product-info {
    color: white;
    font-family: "BUNGEE";
    font-size: 1.8em;
    text-shadow: 0 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
}

.play-product-doublechance {
    color: white;
    font-size: 1em;
    margin-bottom: 10px;
    font-weight: bold;
}