.play-popup {
    position: fixed; /* Ensure it stays fixed in place, covering the screen */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8); /* Slightly transparent background to cover the rest of the page */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align content to the top */
    z-index: 1000; /* Ensure it's above other content */
    overflow-y: auto; /* Enable scrolling if content overflows vertically */
    padding: 50px 0; /* Add padding to the top and bottom */
}

.play-popup-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8); /* Same background for loading */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content when loading */
    z-index: 1000; /* Ensure it's above other content */
    padding: 50px 0;
}

.play-popup-close-button {
    position: absolute; /* Keep it positioned at the top */
    top: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1010; /* Ensure it's above the popup content */
}

.play-popup-close-button img {
    height: 40px; /* Adjust the size as needed */
    width: 40px;
}

.prize-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 15px;
    column-gap: 15px;
    width: 95%;
    padding-bottom: 150px;
}

.play-product-info {
    color: white;
    font-family: "BUNGEE";
    font-size: 1.8em;
    text-shadow: 0 5px rgba(0, 0, 0, 0.5);
}

.play-product-info-arrival {
    color: white;
    font-family: "BUNGEE";
    text-shadow: 0 5px rgba(0, 0, 0, 0.5);
}