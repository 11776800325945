.how-it-works-container {
    padding: 20px;
    text-align: center;
    background-color: #f5f5f5;
    min-height: 60vh; /* Adjust based on your header/footer height */
}

.how-it-works-title {
    color: #333;
    margin-bottom: 20px;
}

.how-it-works-list {
    list-style: none; /* Removes default list styling */
    padding: 0;
    color: #666;
    font-size: 16px;
    text-align: left; /* Align text to the left for list readability */
    max-width: 600px; /* Limits the maximum width of the list */
    margin: auto; /* Centers the list in the container */
}

.how-it-works-list li {
    margin-bottom: 15px; /* Adds space between list items */
}

.how-it-works-list li::before {
    content: '•'; /* Adds a custom bullet point */
    color: #1a0dab; /* Blue color for bullet points */
    font-size: 20px; /* Larger size for visibility */
    padding-right: 10px; /* Space between the bullet point and text */
    vertical-align: middle;
}