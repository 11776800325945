.prize-card {
    width: calc((100% - 40px) / 3); /* Adjust the width as before */
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Create a square container for the image */
.prize-banner-container {
    width: 100%; /* Adjust based on your card's size */
    padding-top: 100%; /* Same as width to maintain a 1:1 aspect ratio */
    position: relative; /* Relative positioning for absolute child */
    border-radius: 28px;
    overflow: hidden; /* Hide overflow to maintain border radius */
    border: 3px solid black; /* Moved border here */
}

.prize-title-box {
    background: linear-gradient(225deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 50%, transparent 50%), #0A89FF;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 48px;
    border-radius: 10px;
    box-shadow: 0 5px 0 0 #5013FF;
    width: 60%; /* Make the title box the width of the card */
    font-weight: bold;
    font-size: 0.8em;
    z-index: 10;
}

.prize-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Make the image take up the entire width of the card */
    height: 100%; /* Maintain aspect ratio */
    border-radius: 25px;
    box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2); /* Increased blur radius and adjusted opacity */
    object-fit: cover; /* Cover the container without losing aspect ratio */
    object-position: center; /* Center the image within the container */
}

.overlay-image {
    position: absolute;
    top: 50%; /* Position at the middle vertically */
    left: 50%; /* Position at the middle horizontally */
    transform: translate(-50%, -50%) rotate(20deg); /* Center the image exactly */
    width: 20%; /* Adjust width to cover a small part of the banner */
    height: auto; /* Maintain aspect ratio */
    z-index: 2; /* Ensure it's above the banner image */
}

.prize-description {
    color: white;
    margin: 5px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    line-height: 1.1em;
}

.prize-letter {
    font-family: 'Bungee';
    font-size: 1.5em;
    color: #FF008A;
    text-shadow: 1.5px 1.5px 0px rgba(253, 192, 255, 1); /* Adjust shadow values as needed */
    margin-bottom: 10px;
}