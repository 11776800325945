.gradient-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #F21103, #FFC2AF, #DAF0FD, #88C6FF);
    min-height: 100vh;
    box-sizing: border-box;
    text-align: center;
}

.products-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.logo {
    height: 100px;
}

.how-to-play-banner {
    width: 90vw;
    margin-top: 20px;
    border-radius: 25px;
    border: 3px solid black;
    box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2); /* Increased blur radius and adjusted opacity */
}

.how-button {
    background: linear-gradient(90deg, #E31587, #FF980E);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 20px 20px;
    font-family: 'Zen Dots', cursive;
    font-size: 2em;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    margin-top: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 80%;
}

.how-button:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    flex-direction: column; /* Add this to stack items vertically */
}

.how-to-close-button {
    position: absolute; /* Position the button absolutely */
    top: 8%; /* Align it to the top */
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 11; /* Make sure it's above the image */
}

.how-to-close-button img {
    height: 40px;
    width: 40px;
}

.popup-image {
    max-width: 85%;
    max-height: 85%;
    position: relative;
}

.try-header {
    font-family: 'Zen Dots';
    font-size: 2.4em;
    color: #333;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 0px;
    width: 80%;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.anime-filter {
    margin-top: 40px;
    margin-right: 30px;
    text-align: right;
    width: 100%;
}

.anime-filter select {
    margin-left: 5px;
    padding: 2px; /* Add padding for better spacing */
    border-radius: 8px; /* Rounded corners */
    border: 2px solid #0A89FF; /* Custom border */
    background-color: white; /* Background color */
    color: #333; /* Text color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
    transition: all 0.3s ease; /* Smooth transition for hover/focus effects */
    appearance: none; /* Remove the default arrow */
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>'); /* Add a custom arrow */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    padding-right: 30px; /* Add space for the arrow */
}

.anime-filter select:hover {
    border-color: #FF79EA; /* Change border color on hover */
}

.anime-filter select:focus {
    outline: none; /* Remove the default focus outline */
    border-color: #FF79EA; /* Add custom border color on focus */
    box-shadow: 0 0 5px rgba(255, 121, 234, 0.5); /* Custom focus shadow */
}

.category-container {
    margin-top: 20px;
    width: 95%
}

.category-section {
    width: 100%;
    margin-bottom: 30px;
}

.category-title {
    font-size: 1.8em;
    margin-bottom: 10px;
    text-align: left;
    margin-left: 5px;
    margin-top: 0;
}

.scroll-container-wrapper {
    position: relative;
    display: flex;
    align-items: left;
    justify-content: left;
    border-top: 2px solid #515151;
    overflow: hidden;
    width: 100%;
}

.product-scroll-container {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
    height: 280px;
    padding: 10px 0;
    scroll-behavior: smooth;
    
    /* Customize the scrollbar appearance */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 #e0e0e0; /* Scrollbar and track colors */
}

.product-scroll-container::-webkit-scrollbar {
    height: 8px; /* Set the height of the scrollbar */
}

.product-scroll-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Roundness of the scrollbar thumb */
}

.product-scroll-container::-webkit-scrollbar-track {
    background-color: #e0e0e0; /* Color of the scrollbar track */
}

.scroll-arrow {
    background: transparent;
    border: none;
    font-size: 2em;
    cursor: pointer;
    z-index: 1;
}

.scroll-arrow.left {
    position: absolute;
    left: 0px;
}

.scroll-arrow.right {
    position: absolute;
    right: 0px;
}

.product-card-container {
    flex: 0 0 auto;
    max-height: 280px;
    margin: 0px 0px;
}
  
.tab-section-wrapper {
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping */
    width: 100%; /* Make sure it takes up full width */
}

.tab-section {
    display: inline-flex; /* Align the tabs horizontally */
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.tab-text {
    font-size: 1.2em;
    cursor: pointer;
    padding: 10px 15px;
    color: gray;
    }

.tab-text.active {
    font-weight: bold;
    color: #0A89FF;
}

.vertical-divider {
    font-size: 1.4em;
    height: 20px; /* Height of the divider */
    width: 1.5px;
    background-color: #515151; /* Divider color */
}
  
.tab-section-wrapper::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
}

.no-products-message {
    text-align: center;
    font-size: 1em;
    color: #666;
    margin: 20px 0;
}
  