.trading-marketplace {
    max-width: 800px;
    padding: 0px 20px;
    border-radius: 8px;
}

.trading-marketplace h1 {
    text-align: center;
    margin-bottom: 20px;
    font-family: "Bungee";
}

.trade-card {
    margin-top: 10px;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    background: #f8f9fa;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
}

.trading-marketplace-user-container {
    text-align: left;
    margin-bottom: 10px;
}

.trading-marketplace-user {
    font-weight: bold;
}

.trade-card ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.trade-card li {
    padding: 5px;
}

.trade-card p {
    font-weight: bold;
}

.loading,
.no-trades {
    text-align: center;
    padding: 20px;
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.create-trade-button {
    background: #25AF19;
    color: white;
    padding: 12px 40px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 70%;
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee';
    margin-bottom: 25px;
    margin-top: 10px;
} 

.trade-offer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.prizes-offered, .prizes-wanted {
    width: 100%;
}
  
.arrow {
    margin: 15px 0;
    font-size: 24px;
}

.prizes-wanted ul {
    margin-bottom: 20px;
}