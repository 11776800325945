.purchase-tokens-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.purchase-tokens-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    font-family: 'Bungee';
}

.token-packages {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.token-package {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 250px;
}

.token-package h2 {
    margin-top: 0;
    font-family: 'Bungee';
    font-size: 1.4em;
}

.purchase-button {
    background: #25AF19;
    color: white;
    border: none;
    padding: 5px 0px;
    margin: 10px;
    border-radius: 20px;
    font-size: 1.2em;
    cursor: pointer;
    box-shadow: 0 6px #184012;
    transition: all 0.3s ease;
    width: 70%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
}

.purchase-button:hover {
    background-color: #45a049;
}  

.token-icon {
    height: 22px;
    margin-right: 5px;
}

.token-package-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns items to the left */
    width: 100%;
}

.token-package-description p {
    text-align: left; /* Ensure all <p> inside token-package-description are aligned left */
    width: 100%;
}


.amount-description {
    display: flex;
    align-items: center; /* Vertically center content */
    justify-content: center; /* Horizontally center content */
    width: 100%;
    margin: 0;
    font-weight: bold;
    font-size: 22px;
}

.discount-percentage {
    width: 100%;
}

.purchase-button {
    background: #25AF19;
    color: white;
    border: none;
    padding: 5px 0px;
    margin: 10px;
    border-radius: 20px;
    font-size: 1.2em;
    cursor: pointer;
    box-shadow: 0 6px #184012;
    transition: all 0.3s ease;
    width: 70%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
}

.purchase-button:hover {
    background-color: #45a049;
}

.token-icon {
    height: 32px;
    margin-right: 5px;
}

.purchase-tokens-promo-banner-container {
    text-align: center;
    margin-top: 10px;
}
  
.purchase-tokens-promo-banner {
    width: 100%;
    height: auto;
    border: 3px solid black; /* Adds a black border */
    border-radius: 10px;
}

.token-terms-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}
  
.token-terms-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 15px rgba(0,0,0,0.3);
    width: 80%;
    max-width: 500px;
    justify-content: center;
    align-items: center;
}
  
.token-terms-content h2 {
    margin-top: 0;
    text-align: center;
}

.token-terms-content p {
    margin-top: 0;
    text-align: center;
}
.token-terms-popup-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Ensures vertical alignment is centered, if needed */
    padding: 0 10px; /* Adds padding to the container, adjust as needed */
    width: 100%;
}

.token-terms-popup-confirm-button, .token-terms-popup-cancel-button {
    flex: 1; /* Each button takes up half the container */
    margin: 0 5px; /* Adds margin to the sides of each button */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    padding: 10px 20px; /* Adequate padding for tapping on mobile */
    border-radius: 20px; /* Rounded corners */
}

.token-terms-popup-confirm-button {
    background: #25AF19; /* Green background */
    box-shadow: 0 4px #184012; /* Darker green shadow for 3D effect */
    border: none; /* No border */
    color: white; /* White text */
    width: 40%;
}

.token-terms-popup-cancel-button {
    background: #DE3163; /* Red background */
    box-shadow: 0 4px #800020; /* Darker red shadow for 3D effect */
    border: none; /* No border */
    color: white; /* White text */
}