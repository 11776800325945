.play-info-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.play-info-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Add shadow */
}

.play-info-popup-title {
    font-size: 32px;
    margin-bottom: 20px;
    margin-top: 0;
    font-family: 'Bungee', sans-serif;
    color: gold;
    text-transform: uppercase;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3); /* Adds depth */
}

.play-info-popup-text {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.5;
    color: #333;
}

.play-info-popup-close-button {
    background: #DE3163;
    color: white;
    padding: 12px 40px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee', sans-serif;
    box-shadow: 0 4px #800020;
    transition: all 0.3s ease;
}

.play-info-popup-close-button:hover {
    background-color: #c82853;
    box-shadow: 0 4px #650013;
}