/* src/components/FAQ.css */
.faq-container {
    padding: 20px;
    text-align: center;
    background-color: #f5f5f5;
    min-height: 60vh;
}

.faq-title {
    color: #333;
    margin-bottom: 20px;
}

.faq-list {
    list-style: none;
    padding: 0;
    color: #666;
    font-size: 16px;
    text-align: left;
    max-width: 600px;
    margin: auto;
}

.faq-section {
    margin-bottom: 30px; /* Add space between sections */
}

.faq-section-title {
    color: #333;
    margin-bottom: 10px;
    font-size: 24px;
    text-align: left; /* Align title to the left */
}

.faq-question {
    background: none;
    border: none;
    text-align: left;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    color: #333;
    cursor: pointer;
}

.faq-answer {
    background-color: #eaeaea;
    padding: 10px;
    margin: 5px 0 15px 0;
    text-align: left; /* Align text to the left */
    color: #333; /* Optional: Improve readability */
    font-size: 16px; /* Optional: Adjust font size if needed */
}

.faq-question:hover, .faq-question:focus {
    color: #1a0dab;
}