.token-payment-container {
    border: 3px solid black; /* Example border */
    background-color: #f9f9f9; /* Example background color */
    border-radius: 28px; /* Rounded corners */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 30px;
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.token-payment-details {
    font-family: 'Bungee';
}

.token-pay-button {
    margin-top: 30px;
    background: #25AF19;
    color: white;
    padding: 12px 40px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 70%;
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee';
}

.promo-code-section {
    display: flex;
    align-items: center; /* Keep input and button aligned vertically */
    justify-content: space-between; /* Ensure proper spacing between input and button */
    width: 80%; /* Same width for section as the message below */
    margin: 0 auto; /* Center the section */
}

.promo-code-input {
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border-color: black;
    flex: 2; /* Take more space compared to the button */
    margin-right: 10px; /* Add space between the input and button */
}
  
.apply-promo-button {
    background: #25AF19;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    flex: 1; /* Take up less space than the input */
    box-shadow: 0 3px #184012; /* Shadow effect */
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee';
    padding: 8px;
}
  
.apply-promo-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.token-payment-token-icon {
    height: 25px;
    margin-right: 5px;
}

.token-promo-error-message, .token-promo-success-message {
    width: 80%; /* Match the width of promo-code-section */
    margin: 5px auto 0 auto; /* Align the message below the input */
    color: red;
    text-align: left; /* Align text with the promo-code-input */
    font-weight: bold;
}

.token-promo-success-message {
    color: #25AF19; /* Green color for success message */
    font-weight: bold;
}

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    overflow: hidden;
}
  