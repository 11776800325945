.shipping-payment-container {
    border: 3px solid black; /* Example border */
    background-color: #f9f9f9; /* Example background color */
    border-radius: 28px; /* Rounded corners */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 30px;
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.shipping-payment-product-image {
    width: 200px;
}

/* .shipping-payment-form {
    top: 0;
    left: 0;
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
} */

.shipping-payment-details {
    font-family: 'Bungee';
}

.shipping-pay-button {
    margin-top: 30px;
    background: #25AF19;
    color: white;
    padding: 12px 40px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 70%;
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee';
}

.shipping-cancel-button {
    margin-top: 30px;
    background: #DE3163;
    color: white;
    padding: 12px 40px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 40%;
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee';
    box-shadow: 0 4px #800020;
}

.shipping-pay-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.shipping-pay-button:hover:not(:disabled) {
    background: #184012;
}

.shipping-stripe-payment-element-form {
    margin-top: 20px;
}