.order-details-title {
    font-family: 'Bungee';
}

.order-extra {
    margin-top: 20px;
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    padding: 0px;
    text-align: left;
}

.order-row {
    width: 90%;
}

.order-date {
    margin-bottom: 3px;
}

.ship-by-date {
    font-weight: bold;
}

.order-details-total-cost {
    text-align: right;
    width: 100%;
    margin-top: 15px;
}

.prizes-won-title {
    font-family: 'Bungee';
}

.order-details-prizes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.order-details-prize-card {
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 88%;
    margin-bottom: 10px;
    padding: 10px;
    height: 100%;
}

.order-details-prize-image {
    width: 300px;
}

.order-details-prize-name {
    width: 100%;
}

.shipment-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.shipment-card {
    background: white;
    border-radius: 10px;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 95%;
}

.arrange-shipment-title {
    color: black;
    display: inline-flex;
    align-items: center; /* Vertically center the text */
    justify-content: center; /* Align text to the left */
    position: relative;
    border-radius: 7px;
    z-index: 2; /* Ensure it's above the banner */
    top: 15px; /* Adjust this value to position the title box above the banner */
    font-family: 'Bungee';
    font-size: 1.5em;
}

.checkboxes {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin-left: 100px;
}

.profile-address-checkbox {
    margin-top: 20px;
}

.another-address-checkbox {
    margin-top: 10px;
}

.confirm-shipment-button {
    background: #25AF19; /* The button's background color */
    color: white; /* The text color */
    font-size: 1.1em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    margin-top: 30px;
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 70%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
}

.cancel-shipment-button {
    background: #DE3163;
    box-shadow: 0 4px #800020;    color: white; /* The text color */
    font-size: 1.1em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    margin: 20px 0; /* Margin above and below the button */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 70%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
}

.shipment-profile-shipping-address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    margin-left: 40px;
}

.shipment-another-shipping-address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    width: 90%;
}

.shipment-profile-fullname {
    margin-top: 10px;
}

.shipment-profile-country, .shipment-profile-postalcode, .shipment-profile-fulladdress, .shipment-profile-unitnumber {
    margin-top: 5px;
}

.shipment-shipping-address-title {
    text-decoration: underline;
}

.shipment-another-shipping-address-title {
    text-decoration: underline;
    margin-bottom: 20px;
    margin-left: 15px;
}

.shipment-input-group input[type="text"] {
  width: 55%; /* Make inputs take the full width */
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px; /* Space between each input group */
  padding: 8px 5px;
  margin-right: 10px;
}

.shipment-input-group select {
    width: 58%; /* Make inputs take the full width */
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px; /* Space between each input group */
    padding: 8px 5px;
    margin-right: 10px;
}

.shipment-input-group {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between;
    width: 100%;
}

.shipment-input-group label {
    margin-left: 15px;
    font-size: 0.9em;
}

.order-details-selected-prizes {
    width: 90%;
    text-align: left;
    margin-bottom: 10px;
}

.shipment-success-message {
    background: #4BB543; /* Success message color */
    color: white;
    text-align: center;
    width: 100%;
    padding: 20px;
    font-size: 1.2em;
    animation: fadeOut 5s forwards;
}
  
@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; visibility: hidden; }
}

.update-shipping-address-button {
    margin-top: 20px;
    background: transparent;
    border: none;
    text-decoration: underline;
    color: #0A89FF;
    padding: 0;
    font-size: 0.9em;
    width: 70%;
    margin-right: 40px;
}

.order-ship-button-group {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: left;
    justify-content: left;
}

.self-collect-button {
    font-size: 0.85em;
    color: #DE3163;
    background: transparent;
    text-decoration: underline;
    border: none;
    cursor: pointer;
    padding: 0px;
    text-align: right;
    margin-top: 10px;
}


.order-expired {
    font-size: 0.8em;
    color: red;
}