.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
}

.popup-content {
    position: relative; /* Add this line */
    width: 100%; /* Ensure it doesn't overflow the viewport */
    height: 100%;
    display: flex;
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    overflow: hidden; /* Hide overflow */
}

.slide-prompt {
    position: absolute;
    bottom: 10%; /* Adjust this value as needed */
    color: #fff;
    text-align: center;
    font-size: 14px;
    animation: glow 0.5s ease-in-out infinite alternate;
    width: 70%;
}
  
@keyframes glow {
    from {
        text-shadow: 0 0 10px #fff;
    }
    to {
        text-shadow: 0 0 20px #fff, 0 0 10px #00FF00;
    }
}

.ticket-open-prompts {
    display: flex;
    flex-direction: column;
}

.ticket-slider {
    -webkit-appearance: none; /* Override default appearance */
    appearance: none; /* Standard CSS for customizing sliders */
    width: 80%; /* Adjust the width as needed */
    height: 2px; /* Specified height for the slider track */
    background: #ddd; /* Light grey track */
    outline: none; /* Remove outline */
    transition: opacity .2s; /* Smooth transition for hover effect */
    cursor: pointer; /* Indicates it's an interactive element */
    position: relative; /* Adjust if necessary, depending on layout */
    top: 20%; /* Vertical positioning */
    z-index: 5; /* Ensure it's above other elements */
}

.ticket-slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default webkit styles */
    appearance: none; /* Removes default style */
    width: 40px; /* Width of the arrow base */
    height: 40px; /* Height of the arrow base */
    background-image: url('/public/images/arrow-right.png'); /* Path to your arrow image */
    background-size: cover; /* Cover the entire area of the thumb */
    background-color: transparent; /* Explicitly set background color to transparent */
    border: none; /* Remove any default border */
    cursor: pointer; /* Cursor appears as pointer on hover */
}

.ticket-slider::-moz-range-thumb {
    appearance: none; /* Removes default style for Firefox */
    width: 40px; /* Same width as for WebKit */
    height: 40px; /* Same height as for WebKit */
    background-image: url('/public/images/arrow-right.png'); /* Ensure it uses the same image */
    background-size: cover; /* Cover the area */
    background-color: transparent; /* Ensure the background is transparent */
    border: none; /* Remove any default border */
    cursor: pointer; /* Same cursor change */
}

.open-ticket-button {
    position: absolute;
    bottom: 18%; /* Adjust this value as needed */
    left: 50%; /* Center the button horizontally */
    transform: translateX(-50%); /* Adjust the horizontal positioning */
    background: #25AF19; /* The button's background color */
    color: white; /* The text color */
    font-size: 1.2em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 5px 0px;
    margin: 15px 0; /* Margin above and below the button */
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 55%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
}

.ticket-animation-next-button {
    position: absolute;
    bottom: 120px; /* Adjust based on your layout */
    left: 50%; /* Center the button horizontally */
    transform: translateX(-50%); /* Adjust the horizontal positioning */
    background: #25AF19; /* The button's background color */
    color: white; /* The text color */
    font-size: 1.2em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 10px 0px;
    margin: 15px 0; /* Margin above and below the button */
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 70%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
}

.draw-button {
    background: #25AF19; /* The button's background color */
    color: white; /* The text color */
    font-size: 1.2em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    margin: 15px 0; /* Margin above and below the button */
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 60%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
}

.popup-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.ticket-animation-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ticket-back {
    position: absolute;
    top: 35%;
    left: 7%;
    z-index: 1;
    background-size: contain; /* or cover */
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none; /* Optional: prevents the animation from capturing clicks */
}

.animation-container {
    position: absolute;
    top: calc(35% + 6px);
    left: 8%;
    z-index: 3; /* Ensure this overlays the static image */
    pointer-events: none; /* Optional: prevents the animation from capturing clicks */
}

.ticket-prize-text {
    position: absolute;
    top: calc(35% + 48px);
    left: calc(7% + 130px);
    z-index: 2; /* Below the animation container but above the ticket back */
    color: white; /* Or any color you prefer */
    font-size: 3.5em; /* Adjust based on your design */
    font-weight: bold;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); /* Optional shadow for better readability */
    font-family: 'Bungee';
}

.ticket-effect {
    position: absolute;
    height: 70%;
    z-index: 0; /* Ensure it's behind everything else */
}

.congratulations-text {
    position: absolute;
    z-index: 4; /* Above the ticket background and animation */
    color: gold;
    font-size: 1.8em;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); /* Optional shadow for better readability */
    top: 15%; /* Adjust based on your layout */
    width: 100%;
    text-align: center;
    font-family: "Bungee";
}

.popup-overlay, .popup-content, .congratulations-text, .ticket-prize-text, .slide-prompt {
    user-select: none; /* Prevent text selection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}
  
.ticket-back, .ticket-effect {
    pointer-events: none; /* Prevent interactions like copy or translate */
}

.challenge-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
}

/* Content of the challenge popup */
.challenge-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Shadow for depth */
    text-align: center;
    width: 80%;
}

/* Header of the popup */
.challenge-popup-content h2 {
    margin-top: 0;
    font-size: 1.8em;
    color: #FBC901;
    font-weight: bold;
}

/* Paragraph in the popup */
.challenge-popup-content p {
    margin: 15px 0;
    font-size: 1.2em;
    color: #666;
}

/* Close button */
.challenge-popup-content button {
    color: white; /* The text color */
    font-size: 1em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 10px; /* Rounded corners */
    padding: 8px 10px;
    margin: 10px 15px; /* Margin above and below the button */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    text-align: center;
    width: 70%;
    background: #DE3163;
    box-shadow: 0 4px #800020;
}

/* Close button hover effect */
.challenge-popup-content button:hover {
    background-color: #0056b3; /* Darker shade for hover */
}