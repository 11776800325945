.about-us-container {
    text-align: center;
    padding: 20px;
    background-color: #f5f5f5;
}

.about-us-logo {
    width: 150px;
    margin-top: 20px;
}

.about-us-heading {
    color: #333;
    margin-bottom: 20px;
}

.about-us-text {
    color: #666;
    font-size: 16px;
    max-width: 600px;
    margin: 20px auto;
    line-height: 1.6;
}

.about-us-image {
    max-width: 90%;
    height: auto;
    margin: 20px 0;
}