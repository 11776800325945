.hold-popup {
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto; /* Enable vertical scrolling */
    width: 100vw;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Align content to the top */
    z-index: 100;
}

.hold-popup-content {
    background: white;
    padding: 20px;
    width: 70%;
    border-radius: 5px;
    border-style: solid;
    border-width: 3px;
    border-color: gray;
}

.refresh-text {
    font-weight: bold;
}

.hold-popup-close-button {
    color: white; /* The text color */
    font-size: 1em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 10px; /* Rounded corners */
    padding: 8px 10px;
    margin: 10px 15px; /* Margin above and below the button */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    text-align: center;
    width: 70%;
    background: #DE3163;
    box-shadow: 0 4px #800020;
}