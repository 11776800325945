.token-confirmation-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.token-confirmation-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 15px rgba(0,0,0,0.3);
    width: 80%;
    max-width: 500px;
}

.token-confirmation-popup-content h2 {
    margin-bottom: 15px;
    font-size: 1.5rem;
}

.token-confirmation-popup-content p {
    margin-bottom: 20px;
    font-size: 1rem;
}

.token-confirmation-popup-buttons {
    display: flex;
    justify-content: space-around;
}

.token-confirmation-button,
.token-cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.token-confirmation-button {
    background: #25AF19; /* Green background */
    box-shadow: 0 4px #184012; /* Darker green shadow for 3D effect */
    border: none; /* No border */
    color: white; /* White text */
    font-family: "Bungee";
    width: 40%;
}

.token-cancel-button {
    background: #DE3163; /* Red background */
    box-shadow: 0 4px #800020; /* Darker red shadow for 3D effect */
    border: none; /* No border */
    color: white; /* White text */
    font-family: "Bungee";
}

.token-confirmation-button:hover {
    background-color: #218838;
}

.token-cancel-button:hover {
    background-color: #c82333;
}

.confirmation-token-icon {
    height: 20px;
    margin-left: 5px;
}