.draw {
    top: 0;
    left: 0;
    overflow-y: auto; /* Enable vertical scrolling */
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align content to the top */
    z-index: 10;
}

.draw-product-title-box {
    background: #0A89FF;
    color: white;
    display: inline-flex;
    align-items: center; /* Vertically center the text */
    justify-content: center; /* Align text to the left */
    padding: 8px 12px;
    position: relative;
    border-radius: 12px;
    font-weight: bold;
    font-size: 0.8em;
    z-index: 2; /* Ensure it's above the banner */
    top: 15px; /* Adjust this value to position the title box above the banner */
    max-width: 70%;
    white-space: nowrap; /* Keep text in a single line */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add an ellipsis if the text is too long */
    margin-bottom: 25px;
}
  
.shake-button {
    background: #7A85F4; /* The button's background color */
    color: white; /* The text color */
    font-size: 1.2em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    margin: 15px 0; /* Margin above and below the button */
    box-shadow: 0 6px #4B4B9E; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 60%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
}

.mmk-button {
    background: pink; /* The button's background color */
    color: white; /* The text color */
    font-size: 1.2em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    margin: 15px 0; /* Margin above and below the button */
    box-shadow: 0 6px #FF92A5; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 60%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
}

.mmk-button:hover {
    background-color: #ff69b4; /* A darker shade of pink for hover effect */
  }

.draw-button {
    background: #25AF19; /* The button's background color */
    color: white; /* The text color */
    font-size: 1.2em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    margin: 15px 0; /* Margin above and below the button */
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 60%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
}
  
.kuji-box {
    max-width: 75%;
    margin-top: 10px;
    /* Add additional styles if needed */
}
  
.button-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    padding: 20px;
} 

.kuji-box.shake {
    animation-name: shake-and-tilt; /* We'll dynamically insert the keyframes with this name */
    /* Do not set animation-duration or animation-iteration-count here */
}

.slider-container {
    width: 80%; /* Adjust as needed */
    margin-top: 15px;
}

.slider {
    -webkit-appearance: none; /* Override default look on WebKit browsers */
    appearance: none;
    width: 100%; /* Full width */
    height: 12px; /* Set the slider thumb height */
    background: gray; /* Background of the slider */
    outline: none; /* Remove the focus outline */
    opacity: 0.8; /* Partial transparency */
    -webkit-transition: .2s; /* Smoothing transition */
    transition: opacity .2s;
    border-radius: 5px;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look on WebKit browsers */
    appearance: none;
    width: 25px; /* Width of the slider thumb */
    height: 25px; /* Height of the slider thumb */
    background: #7A85F4; /* Green background */
    cursor: pointer; /* Cursor changes when hovering over the slider thumb */
    border-radius: 50px;
}

.tickets-left {
    color: #fff; /* Adjust color to fit your theme */
    margin-top: 5px; /* Adjust spacing as needed */
    font-size: 15px; /* Adjust font size as needed */
}

.volume-container {
    display: flex;
    width: 95%;
    align-items: left;
    margin-top: 20px;
}

.sound-toggle-icon {
    width: 30px;
}