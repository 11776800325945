/* UserTokensPage.css */
.user-tokens-container {
    width: 80%;
    background-color: #ffffffdd;
    padding: 20px;
    border-radius: 8px;
    color: #333;
    margin-top: 20px;
}

.user-tokens-title {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 0;
}

.user-tokens-section h2 {
    margin-top: 20px;
}

.user-tokens-section ul {
    list-style-type: none;
    padding: 0;
}

.user-tokens-section li {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.user-token-icon {
    width: 20px;
    height: 20px;
    margin-right: 3px;
}

.active-token-list-item {
    display: flex;
}

.expired-token-list-item {
    display: flex;
}

.purchase-tokens-button-container {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 30px;
}
  
.purchase-tokens-button {
    background-color: #25AF19;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    font-size: 1.2em;
    cursor: pointer;
    box-shadow: 0 6px #184012;
    transition: all 0.3s ease;
    font-family: 'Bungee', sans-serif;
}
  
.purchase-tokens-button:hover {
    background-color: #45a049;
}
  